import queryString from 'query-string';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  APIDocument,
  DefaultListParams,
  Directory,
  DirectoryAction,
  DocumentComment,
  DocumentsCharts,
  IdsParams,
  WithPagination
} from 'types';

import {
  BulkActions,
  ChartParams,
  CreateDelegateDocument,
  DelegatedApprovalDocument,
  DelegatedDocument,
  DirectoriesParams,
  DocumentStats,
  DocumentsParams,
  DownloadBackgroundZip,
  DownloadFiles,
  SendToEmailBody,
  StatsParams
} from './documents.types';
import { axios } from './axios';

export const documents = {
  getStats: async (params?: StatsParams) => {
    const { data } = await axios.get<DocumentStats>('/documents/stats', {
      params
    });

    return data;
  },
  getList: async (params?: DocumentsParams) => {
    const { data } = await axios.get<WithPagination<APIDocument>>('/documents/', {
      params,
      paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'comma' })
    });

    return data;
  },
  getById: async (id: number) => {
    const { data } = await axios.get<APIDocument>(`/documents/${id}`);

    return data;
  },
  getPng: async (id: number) => {
    const { data } = await axios.post<string[]>(`/documents/${id}/convert-to-png`);

    return data;
  },
  getCharts: async (params?: ChartParams) => {
    const { data } = await axios.get<DocumentsCharts[]>(`/documents/charts`, {
      params,
      paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'comma' })
    });

    return data;
  },
  getPartnerStats: async (params?: DefaultListParams) => {
    const { data } = await axios.get<DocumentStats>('/users/partner/document-stats', {
      params
    });

    return data;
  },
  getClientStats: async (params?: DefaultListParams) => {
    const { data } = await axios.get<DocumentStats>('/users/client/document-stats', {
      params
    });

    return data;
  },
  postComment: async (data: {
    documentId: number;
    response: number;
    thread_id: string;
    text: string;
  }) => {
    const response = await axios.post<DocumentComment>(
      `/documents/${data.documentId}/comment`,
      data
    );
    return response.data;
  },
  getComments: async (id: number, params?: DefaultListParams) => {
    const response = await axios.get<WithPagination<DocumentComment>>(`/documents/${id}/comments`, {
      params,
      paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'comma' })
    });
    return response.data;
  },
  sendToApollo: async (ids: number[]) => {
    const { data } = await axios.post(
      queryString.stringifyUrl(
        {
          url: `/documents/send-to-apollo`,
          query: { ids }
        },
        { arrayFormat: 'comma' }
      )
    );
    return data;
  },
  delete: async (id: number) => {
    await axios.delete(`/documents/${id}`);
  },
  deleteBulk: async (params: BulkActions) =>
    await axios
      .delete('/documents/bulk-delete', {
        params,
        paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'comma' })
      })
      .then(({ data }) => data),

  confirmUpload: async (params?: IdsParams) =>
    await axios
      .post(
        '/documents/confirm-upload-files',
        {},
        {
          params,
          paramsSerializer: (params) => queryString.stringify(params, { arrayFormat: 'comma' })
        }
      )
      .then(({ data }) => data),

  downloadFiles: async (params: DownloadFiles) => {
    const { data } = await axios.post(
      `/documents/download-files?${queryString.stringify(params, {
        arrayFormat: 'comma'
      })}`,
      {},
      {
        responseType: Array.isArray(params?.ids) || params.directory ? 'json' : 'blob'
      }
    );

    return { file: data, name: params?.name };
  },
  rejectDocument: async (id: number) => {
    const { data } = await axios.post(`/documents/${id}/reject`);

    return data;
  },
  getFolders: async (id?: number) => {
    const { data } = await axios.get<string[]>(`/users/partner/${id}/folders`);

    return data;
  },
  changeFolder: async (data: { folder_id: number; ids: number[] }) => {
    const response = await axios.patch(`/documents/change-folder`, data);

    return response.data;
  },
  getDirectoriesList: async (params?: DirectoriesParams) => {
    const { data } = await axios.get<WithPagination<Directory>>('/documents/directories', {
      params
    });

    return data;
  },
  getDirectoriesSearchList: async (params?: DirectoriesParams) => {
    const { data } = await axios.get<WithPagination<Directory>>('/documents/directories/search', {
      params
    });

    return data;
  },
  getDirectoryById: async (id: number, params: DirectoriesParams = {}) => {
    const { data } = await axios.get<Directory>(`/documents/directories/${id}`, { params });

    return data;
  },
  createDirectory: async (body: DirectoryAction) => {
    const { data } = await axios.post<Directory>(`/documents/directories`, body);

    return data;
  },
  editDirectory: async (id: number, body: DirectoryAction) => {
    const { data } = await axios.patch<Directory>(`/documents/directories/${id}`, body);

    return data;
  },
  deleteDirectory: async (id: number) => {
    const { data } = await axios.delete<Directory>(`/documents/directories/${id}`);

    return data;
  },
  createDelegatedDocument: async (body: CreateDelegateDocument) => {
    const { data } = await axios.post<DelegatedApprovalDocument>(`/documents/delegations`, body);

    return data;
  },
  getDelegatedDocumentById: async (id: number) => {
    const { data } = await axios.get<DelegatedDocument>(`/documents/delegations/${id}`);

    return data;
  },
  approveDelegatedDocument: async (id: number) => {
    const response = await axios.patch(`/documents/delegations/${id}/approve`);

    return response.data;
  },
  cancelDelegatedDocument: async (id: number) => {
    const response = await axios.patch(`/documents/delegations/${id}/cancel`);

    return response.data;
  },
  rejectDelegatedDocument: async (id: number) => {
    const response = await axios.patch(`/documents/delegations/${id}/reject`);

    return response.data;
  },
  checkZipDocument: async (id: number) => {
    const response = await axios.get<DownloadBackgroundZip>(`/documents/zip/${id}`);

    return response.data;
  },
  cancelZipTask: async (id: number) => {
    const response = await axios.delete(`/documents/zip/${id}`);

    return response.data;
  },
  downloadExcelFiles: async (params: DownloadFiles) => {
    const { data } = await axios.get(
      `/documents/excel-sheet?${queryString.stringify(params, {
        arrayFormat: 'comma'
      })}`,
      {
        responseType: 'blob'
      }
    );

    return { file: data, name: params?.name };
  },
  sendToEmail: async ({
    body,
    params
  }: {
    body: SendToEmailBody;
    params: DownloadFiles;
  }) => {
    const { data } = await axios.post(
      `/documents/send-to-email?${queryString.stringify(params, {
        arrayFormat: 'comma'
      })}`,
      body
    );

    return data;
  }
};

export const documentsQueries = createQueryKeys('documents', {
  getStats: (params: StatsParams = {}) => ({
    queryKey: [params],
    queryFn: () => documents.getStats(params)
  }),
  getPartnerStats: (params = {}) => ({
    queryKey: [params],
    queryFn: () => documents.getPartnerStats(params)
  }),
  getList: (params: DocumentsParams = {}) => ({
    queryKey: ['list', params],
    queryFn: (context) => documents.getList({ page: context.pageParam, ...params })
  }),
  getById: (id: number) => ({
    queryKey: [id],
    queryFn: () => documents.getById(id)
  }),
  getPng: (id: number) => ({
    queryKey: [id],
    queryFn: () => documents.getPng(id)
  }),
  getCharts: (params: ChartParams = {}) => ({
    queryKey: [params],
    queryFn: () => documents.getCharts(params)
  }),
  getClientStats: (params: DefaultListParams = {}) => ({
    queryKey: [params],
    queryFn: () => documents.getClientStats(params)
  }),
  rejectDocument: (id: number) => ({
    queryKey: [id],
    queryFn: () => documents.rejectDocument(id)
  }),
  getFolders: (id?: number) => ({
    queryKey: ['list', 'folders'],
    queryFn: () => documents.getFolders(id)
  }),
  getDirectoriesList: (params: DirectoriesParams = {}) => ({
    queryKey: ['directories', params],
    queryFn: (context) => documents.getDirectoriesList({ page: context.pageParam, ...params })
  }),
  getDirectoriesSearchList: (params: DirectoriesParams = {}) => ({
    queryKey: ['directories', params],
    queryFn: (context) => documents.getDirectoriesSearchList({ page: context.pageParam, ...params })
  }),
  getDirectoryById: (id: number, params: DirectoriesParams = {}) => ({
    queryKey: ['directories', params, id],
    queryFn: (context) => documents.getDirectoryById(id, { ...params, page: context.pageParam })
  }),
  getDelegatedDocumentById: (id: number) => ({
    queryKey: ['delegated', id],
    queryFn: () => documents.getDelegatedDocumentById(id)
  }),
  checkZipDocument: (id: number) => ({
    queryKey: ['check-zip', id],
    queryFn: () => documents.checkZipDocument(id)
  }),
  getComments: (id: number, params?: DefaultListParams) => ({
    queryKey: ['comments', id, params],
    queryFn: (context) => documents.getComments(id, { ...params, page: context.pageParam })
  })
});
